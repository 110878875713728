<template>
  <main class="container">
    <div class="header h3 mt-2">
          Detalles de Conocimiento / {{diverseProductDocs.diverseProductDocId}}
        <!-- <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Crear Nuevo</button> -->
    </div>

    <!-- DETAILS -->
    <!-- <div class="row mt-2 mx-1" style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px"> -->
    <div class="row mt-2 " style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px">
        <div class="row">
            <!-- <div class="col-md-6">
                <label class="text-green-2 mt-2">Almacén de salida:</label>
                <select class="form-select border-secondary" required v-model="newItem.warehouseId" @change="selectWarehouse()">
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                </select>            
            </div> -->
            <div class="col-md-6">
                <!-- <label class="text-white mt-2">Número de identificación Tributaria: {{diverseProductDocs.nit}}</label><br> -->
                <!-- <label class="text-white mt-2">Nombre del responsable: {{row.receiverUserName}}</label><br> -->
                <label class="text-white mt-2">Sub-Estación: {{row.receiverStationName}}</label><br>
                <label class="text-white mt-2">Emisor: {{row.username}}</label><br>
                <!-- <label class="text-white mt-2">Puesto que desempeña: {{row.employeeRole}}</label><br> -->
            </div>
            <div class="col-md-6">
                <label class="text-white mt-2">Fecha: {{$moment(row.creationDate).format('DD-MM-YYYY')}}</label><br>
                <label class="text-white mt-2">Estado: {{row.status== 1 ? 'Activo' : row.status == 2 ? 'En tránsito' : row.status == 0 ? 'Inactivo' :'--' }}</label><br>
                <!-- <label class="text-white mt-2">Fecha de entrada: {{row.senderUserName}}</label> -->
            </div>
        </div>
        <div class="row" style="top:10px">
            <b-table 
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(price)="data">
                    <div>                        
                        <span>{{'Q ' + data.item.price.toFixed(2)}}</span>
                    </div>
                </template>
                 <template #cell(fecha)="data">
                    <div>                        
                        <span>{{$moment(row.creationDate).format('DD-MM-YYYY')}}</span>
                    </div>
                </template>
            </b-table> 

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn-2 float-end mt-3" @click="print()">Vista preliminar&nbsp;<i class="fas fa-print me-2"/></button>
            </div>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        row:{},
        fields: [
            { key: "fecha", label:"Fecha de cargo"},
          { key: "code", label:"Código"},
          { key: "name", label:"Descripción"},
          { key: "observations", label:"Observaciones"},
          { key: "price", label:"Valor del bien"},
        //   { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        showEditModal:false,
        table: 'transfers',
        transferId: 0,
        diverseProductDocs:[]
    }
},
async mounted(){
    this.transferId = this.$route.params.id;
    await this.getData()
},
methods:{
    print(){
        this.$router.push('/transfers/detailsDiverseProductPreview/'+this.transferId)
    },
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getTransferdiverseProductDocDetails/'+ this.transferId});
            // console.log(res.data)
            if (res){
                this.row = res.data[0]
                this.items = res.items
                this.diverseProductDocs = res.diverseProductDocs[0]

            }
        } catch (error) {
            this.row = {}
            console.error('error', error)
        }
    }
     

}
}
</script>

<style>

</style>